// From "gatsby-plugin-layout"
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { StaticImage } from 'gatsby-plugin-image';
import store from '../../store';
import { AppContextProvider } from '../../services/context/AppContext';
import AppBar from '../../components/AppBar';
import ThemeProvider from '../ThemeProvider';

const Layout = ({ children }) => {
  const { language, languages, originalPath } = useI18next();
  // console.log('lnaguage',language, languages, originalPath);
  return (
    <AppContextProvider>
      <ReduxProvider store={store}>
        <ThemeProvider>
          {/* <AppBar /> */}
          <Container>
            <Helmet>
              <html lang={language} />
              <meta name="viewport" content="initial-scale=1, width=device-width" />
              <link
                href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
                rel="stylesheet"
              />
            </Helmet>
            <StaticImage
              src="../../assets/images/Lavoic-Logo-light.png"
              // width={300}
              // height={20}
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt="A Gatsby astronaut"
              style={{ marginTop: '1.45rem', marginBottom: '1.45rem' }}
            />
            <Typography variant="h1" component="div" gutterBottom align="center">
              Rundown
            </Typography>
            {/* <div>
              <ul className="languages">
                {languages.map((lng) => (
                  <li key={lng}>
                    <Link to={originalPath} language={lng}>
                      {lng}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {children} */}
          </Container>
        </ThemeProvider>
      </ReduxProvider>
    </AppContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Layout;
